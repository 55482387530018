import { Modal, Button } from 'antd';
import { useState } from 'react';
import {useLightgallery} from 'react-lightgallery';
import GalleryImage from '../Media/GalleryImage';
import "lightgallery.js/dist/css/lightgallery.css";


const REACT_APP_API_QUOTESIMG_URL = process.env.REACT_APP_API_QUOTESIMG_URL
const QuoteItem = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const galleryData = props.dataGallery; 

  const {openGallery} = useLightgallery(); 

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='quote-item-box'>
      <img className={'quote-image'} src={props.image} alt='' />
      
      <Button
        type='dash'
        shape='round'
        onClick={showModal}
        style={{
          maxWidth: '75%',
        }}
      >
        {props.title}
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        title="Gallery"
        maskClosable={true}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        width={'100rem'}
      >
        <div className="gallery-list">
         {galleryData.map( (item, i) => (
            <div key = {i} className="gallery-item" onClick={() => ( openGallery(item.title))}>
              <p className ="gallery-textTitle">{item.title}</p>
              <img className ="gallery-img" src={item.galleryThumbnail} />
            </div>
         ))}
        </div>
      </Modal>
      <div style={{ display:"none" }}>
           {galleryData.map( filtered => 
                (filtered.galleryImgList.map((l, i) => 
                    <GalleryImage key ={i} image={`${REACT_APP_API_QUOTESIMG_URL}` + l} thumb={`${REACT_APP_API_QUOTESIMG_URL}`+ l} group={filtered.title} />
                ))
            )}
      </div>
    </div>
  );
};
export default QuoteItem;
