import { Carousel } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import 'App.scss';
import React, {useEffect,useContext} from 'react';
import {Context} from 'components/Language';
import {useDispatch, useSelector}from 'react-redux';
import { getData_Testinomial } from 'actions';

import VietNam from '../../lang/vn.json';
import {FormattedMessage} from 'react-intl';


const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;

function TestimonialsSlider() {
  const context = useContext(Context)
  
  useEffect(() => {},[context.locale])

  const dispatch = useDispatch();

  useEffect (() => {
      dispatch(getData_Testinomial(`${REACT_APP_API_ADMIN_URL}/testinomial/get-all-testinomial`))
  },[])

  const thedata = useSelector( state => state.fetchTesti)
  

  const data = []
  if (context.locale === "en"){
    thedata.data?.forEach(element => data.push({
      name : element.name?.en,
      jobTitle : element.jobTitle?.en,
      testino : element.testinomial?.en,
      ava : element.avatarUrl,
      id : element.id,
    }));
  }
  else {
    thedata.data?.forEach(element => data.push({
      name : element.name?.vi,
      jobTitle : element.jobTitle?.vi,
      testino : element.testinomial?.vi,
      ava : element.avatarUrl,
      id : element.id,
    }));
  }
  return (
    <div className='slider_container'>
      <h1 className='slider_header'><FormattedMessage id="home-page.title-testimonial" defaultMessage={VietNam['home-page']['title-testimonial']}/></h1>
      <Carousel autoplay arrows={true}  nextArrow={<CaretRightOutlined />} prevArrow={<CaretLeftOutlined />}>
        {data.map((person) => (
          <div key={person.id} className='slider_content'>
            <div className='slide_item-container'>
              <div className='slide_item-content'>
                <div className='portrait-mobile-img'>
                  <img className='portrait-mobile' src={person.ava}></img>
                </div>
                <div className='slide_item-text'>
                  {person.name}
                  <br></br>
                  {person.jobTitle}
                </div>
                <div className='slide_item-text-mobile'>
                  <h2>{person.name}</h2>
                  <p>{person.jobTitle}</p>
                </div>
              </div>
              <p className='slide_item-footer'>{person.testino}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default TestimonialsSlider;
