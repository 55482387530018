import CauouselSliderContainer from 'components/CarouselSliderContainer'; 

import startup1 from './assets/startup1.jpg';
import startup2 from './assets/startup2.jpg';
import nothumbnails from './assets/nothumbnails.png';
import {FormattedMessage, FormattedDate} from 'react-intl';
import VietNam from '../../lang/vn.json';

import React,{useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getData_Vid, getYoutubeThumbnail} from 'actions';
import CustomSlider from 'components/Media/CustomerSlider';

const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;

const Startup = () => {

  
  const dispatch =useDispatch();
  useEffect(() => {
    dispatch(getData_Vid(`${REACT_APP_API_ADMIN_URL}/media/get-all-media-collection/video`))
  }, [])



  const data = useSelector(state => state.fetchVid);

  const filter_data = data.data?.filter((item) => item.mediaCategory === "startup")

  const final_data = []

  filter_data?.map((i) => (
      i.mediaList.map( (link) => ( final_data.push(getYoutubeThumbnail(link))))
    ))


  return (
    <>
    <div className='startup-content insurance-content-mobile'>
      <p>
        <FormattedMessage id="bado-page.para1" defaultMessage={VietNam['bado-page']['para1']}/>
      </p>
      <p>
        <FormattedMessage id="bado-page.para2" defaultMessage={VietNam['bado-page']['para2']}/>
      </p>
      <p>
        <FormattedMessage id="bado-page.para3" defaultMessage={VietNam['bado-page']['para3']}/>
      </p>
      <p>
      <FormattedMessage id="bado-page.para4" defaultMessage={VietNam['bado-page']['para4']}/>
      </p>
      <p>
      <FormattedMessage id="bado-page.para5" defaultMessage={VietNam['bado-page']['para5']}/> <a className="link-click" href="https://svv.vn" >svv.vn</a>
      </p>
    </div>
    <CauouselSliderContainer vidlist= {final_data} linkClass={"carousel-media"} title={<FormattedMessage id="bado-page.vid-section-title" defaultMessage={VietNam['bado-page']['vid-section-title']}/>} totalNum ={3}/>
    <CustomSlider/>
    </>
  );
};

export default Startup;
