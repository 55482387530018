const fetchVidReducers = (state=[], action) => {
    switch( action.type){
        case 'SET_DATA_VID': 
            return (state = action.data); 
        default: 
            return state;
    }
}


export default fetchVidReducers; 