import React, {createContext, useEffect} from 'react';
import "antd/dist/antd.css";
import "react-modal-video/scss/modal-video.scss";

import News from 'components/News/News';
import Contribute_Descrpition from '../../components/Contrib Header/index';
import HeaderQuoteImg from '../Media/assets/media-07.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlider from 'components/Media/CustomerSlider';
import CarouselSlideContainer from 'components/CarouselSliderContainer';


import { useSelector, useDispatch } from 'react-redux';
import { getData_Vid, getYoutubeThumbnail} from 'actions';


import {FormattedMessage} from 'react-intl';
import VietNam from '../../lang/vn.json';


export const AppContext = createContext(null); 

const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;

const Media = () => {
  
  const dispatch =useDispatch();
  useEffect(() => {
    dispatch(getData_Vid(`${REACT_APP_API_ADMIN_URL}/media/get-all-media-collection/video`))
  }, [])



  const data = useSelector(state => state.fetchVid);

  const filter_data = data.data?.filter((item) => item.mediaCategory === "other")

  const final_data = []

  filter_data?.map((i) => (
      i.mediaList.map( (link) => ( final_data.push(getYoutubeThumbnail(link))))
    ))

  return (
    <>
      <div>
        <Contribute_Descrpition img={HeaderQuoteImg} title={'MEDIA'} lightText={true} /> 
        <CustomSlider/>
        <div className="media-news-title news-spacecing">
            <FormattedMessage id="media.title-baochi" defaultMessage={VietNam['media']['title-baochi']}/>
        </div>
        <News/>
        <CarouselSlideContainer vidlist={final_data} linkClass={"carousel-media"} title={<div className='media-news-title'><FormattedMessage id="media.title-vid" defaultMessage={VietNam['media']['title-vid']}/></div>} totalNum={3}/>
  
      </div>

    </>
  );
};

export default Media;
