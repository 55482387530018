import { combineReducers } from "redux";
import fetchNewReducers from "../reducers/fetchnew";
import fetchImgReducers from '../reducers/fetchimg';
import fetchVidReducers from "../reducers/fetchvid";
import fetchTesTiReducers from "../reducers/fetchtesti";
import fetchquotesReducer from "../reducers/fetchquotes";
import reducerFetchText from "../reducers/fetchText"

const allReducers  = combineReducers({
    fetchImg : fetchImgReducers,
    fetchNew : fetchNewReducers,
    fetchVid : fetchVidReducers,
    fetchTesti : fetchTesTiReducers,
    fetchQuotes : fetchquotesReducer,
    fetchText : reducerFetchText 
})


export default allReducers;