const fetchquotesReducer = (state= [], action) => {
    switch (action.type) {
        case "SET_DATA_QUOTES": 
            return (state = action.data) 

        default: 
            return state; 
    }
}


export default fetchquotesReducer;