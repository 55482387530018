import CauouselSliderContainer from 'components/CarouselSliderContainer'; 

import VietNam from '../../lang/vn.json';

import {FormattedMessage} from 'react-intl';

import {Context} from 'components/Language';
import React,{useEffect, useContext} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getData_Vid, getYoutubeThumbnail} from 'actions';
import CustomSlider from 'components/Media/CustomerSlider';


const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;

const Water = (props) => {
  const context = useContext(Context);
  const dispatch =useDispatch();
  useEffect(() => {
    dispatch(getData_Vid(`${REACT_APP_API_ADMIN_URL}/media/get-all-media-collection/video`))
  }, [])



  const data = useSelector(state => state.fetchVid);

  const filter_data = data.data?.filter((item) => item.mediaCategory === "water")

  const final_data = []
  const text_data = []

  filter_data?.map((i) => (
      i.mediaList.map( (link) => ( final_data.push(getYoutubeThumbnail(link))))
    ))


  const water_data = props.data.data?.filter((item) => item.textCategory === "water") 


  if (context.locale ==="en"){
    water_data && water_data[0].content.en.forEach(
      item => text_data.push(item)
    )
  }else {
    water_data && water_data[0].content.vi.forEach(
      item => text_data.push(item)
    )
  }
  
  return (
    <>
    <div className='water-content insurance-content-mobile'>     
      {
      text_data.map((item, i) => 
          { 
            if ( i === text_data.length - 1) {
              return (
                <p>{text_data[text_data.length - 2]} <a className="link-click" href="https://aquaone.vn" > {item}</a></p>
              )
            }
            else if (i === text_data.length -2) {
              return (<></>)
            }
            else {
              return (
                <p>{item}</p>
              )
            }  
          }
      ) 
      }
    </div>
    
    <CauouselSliderContainer vidlist= {final_data} linkClass={"carousel-media"} title={<FormattedMessage id="nuocsach-page.vid-section-title" defaultMessage={VietNam['nuocsach-page']['vid-section-title']}/>} totalNum ={3}/>
    
    <CustomSlider/>
    </> 
  );
};

export default Water;
