import CauouselSliderContainer from 'components/CarouselSliderContainer'
import baohiem1 from './assets/baohiem1.jpg';
import baohiem2 from './assets/baohiem2.jpg';
import {isMobile} from "react-device-detect";
import {FormattedMessage, FormattedDate} from 'react-intl';
import VietNam from '../../lang/vn.json';

import React,{useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getData_Vid, getYoutubeThumbnail} from 'actions';
import CustomSlider from 'components/Media/CustomerSlider';

const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;
const Insurance = () => {

  
  const dispatch =useDispatch();
  useEffect(() => {
    dispatch(getData_Vid(`${REACT_APP_API_ADMIN_URL}/media/get-all-media-collection/video`))
  }, [])



  const data = useSelector(state => state.fetchVid);

  const filter_data = data.data?.filter((item) => item.mediaCategory === "ensurance")

  const final_data = []

  filter_data?.map((i) => (
      i.mediaList.map( (link) => ( final_data.push(getYoutubeThumbnail(link))))
    ))


  if (isMobile){
    return (
      <>
      <div className='insurance-content-mobile insurance-content'>
        <p>
          <FormattedMessage id="baohiem-page.para1" defaultMessage={VietNam['baohiem-page']['para1']}/>
        </p>
        <p><FormattedMessage id="baohiem-page.para2" defaultMessage={VietNam['baohiem-page']['para2']}/></p>
        <p><FormattedMessage id="baohiem-page.para2-li1" defaultMessage={VietNam['baohiem-page']['para2-li1']}/></p>
        <p>
          <FormattedMessage id="baohiem-page.para2-li2" defaultMessage={VietNam['baohiem-page']['para2-li2']}/>
        </p>
        <p><FormattedMessage id="baohiem-page.para2-li3" defaultMessage={VietNam['baohiem-page']['para2-li3']}/></p>
        <p>
          <FormattedMessage id="baohiem-page.para2-li4" defaultMessage={VietNam['baohiem-page']['para2-li4']}/>
        </p>
        <p>
          <FormattedMessage id="baohiem-page.para2-li5" defaultMessage={VietNam['baohiem-page']['para2-li5']}/>
        </p>
        <p>
        <FormattedMessage id="baohiem-page.para3" defaultMessage={VietNam['baohiem-page']['para3']}/> <a className="link-click" href="https://lian.vn" >lian.vn</a>
        </p>
      </div>
      <CauouselSliderContainer vidlist= {final_data} linkClass={"carousel-2items"} title={<FormattedMessage id="baohiem-page.vid-section-title" defaultMessage={VietNam['baohiem-page']['vid-section-title']}/>} totalNum ={1}/> 
      <CustomSlider/>
      </>
    )
  }
  return (
    <>
    <div className='insurance-content'>
      <p>
        <FormattedMessage id="baohiem-page.para1" defaultMessage={VietNam['baohiem-page']['para1']}/>
      </p>
      <p><FormattedMessage id="baohiem-page.para2" defaultMessage={VietNam['baohiem-page']['para2']}/></p>
      <p><FormattedMessage id="baohiem-page.para2-li1" defaultMessage={VietNam['baohiem-page']['para2-li1']}/></p>
      <p>
        <FormattedMessage id="baohiem-page.para2-li2" defaultMessage={VietNam['baohiem-page']['para2-li2']}/>
      </p>
      <p><FormattedMessage id="baohiem-page.para2-li3" defaultMessage={VietNam['baohiem-page']['para2-li3']}/></p>
      <p>
      <FormattedMessage id="baohiem-page.para2-li4" defaultMessage={VietNam['baohiem-page']['para2-li4']}/>
      </p>
      <p>
      <FormattedMessage id="baohiem-page.para2-li5" defaultMessage={VietNam['baohiem-page']['para2-li5']}/>
      </p>
      <p>
      <FormattedMessage id="baohiem-page.para3" defaultMessage={VietNam['baohiem-page']['para3']}/> <a className="link-click" href="https://lian.vn" >lian.vn</a>
      </p>
    </div>
    <CauouselSliderContainer vidlist= {final_data} linkClass={"carousel-media"} title={<FormattedMessage id="baohiem-page.vid-section-title" defaultMessage={VietNam['baohiem-page']['vid-section-title']}/>} totalNum ={3}/> 
    <CustomSlider/>
    </>
  );
};

export default Insurance;
