const webRoute = {
  home: {
    path: '/',
  },
  biography: {
    path: '/biography',
  },
  contribution: {
    path: '/contribution',
  },
  quotes: {
    path: '/quotes',
  },
  media: {
    path: '/media',
  },
  contributionDetails: {
    path: '/details',
  },
};

export default webRoute;
