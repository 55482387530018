import CauouselSliderContainer from 'components/CarouselSliderContainer'; 

import ngoaigiao1 from './assets/ngoaigiao1.jpg';
import ngoaigiao2 from './assets/ngoaigiao2.jpg';
import ngoaigiao3 from './assets/ngoaigiao3.jpg';
import {FormattedMessage, FormattedDate} from 'react-intl';
import VietNam from '../../lang/vn.json';

import React,{useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getData_Vid, getYoutubeThumbnail} from 'actions';
import CustomSlider from 'components/Media/CustomerSlider';

const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;

const Foreign = () => {

  const dispatch =useDispatch();
  useEffect(() => {
    dispatch(getData_Vid(`${REACT_APP_API_ADMIN_URL}/media/get-all-media-collection/video`))
  }, [])



  const data = useSelector(state => state.fetchVid);

  const filter_data = data.data?.filter((item) => item.mediaCategory === "foreign")

  const final_data = []

  filter_data?.map((i) => (
      i.mediaList.map( (link) => ( final_data.push(getYoutubeThumbnail(link))))
    ))


  return (
    <>
    <div className='foreign-content insurance-content-mobile'>
      <p>
        <FormattedMessage id="ngoaigiao-page.para1" defaultMessage={VietNam['ngoaigiao-page']['para1']}/>
      </p>
      <p>
        <FormattedMessage id="ngoaigiao-page.para2" defaultMessage={VietNam['ngoaigiao-page']['para2']}/>
      </p>
      <p>
        <FormattedMessage id="ngoaigiao-page.para3" defaultMessage={VietNam['ngoaigiao-page']['para3']}/>
      </p>
      <p>
        <FormattedMessage id="ngoaigiao-page.para4" defaultMessage={VietNam['ngoaigiao-page']['para4']}/>
      </p>
      <p>
      <FormattedMessage id="ngoaigiao-page.para5" defaultMessage={VietNam['ngoaigiao-page']['para5']}/> <a className="link-click" href="http://southafricahc.com" >southafricahc.com</a>
      </p>
    </div>
    <CauouselSliderContainer vidlist= {final_data} linkClass={"carousel-media"} title={"Các video liên quan"} totalNum ={3}/>
    <CustomSlider/>
    </>
  );
};
export default Foreign;
