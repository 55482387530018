import {useContext, useState} from 'react';
import {Context} from 'components/Language';
import "antd/dist/antd.css"; 
import {FormattedMessage} from 'react-intl';
import VietNam from '../../lang/vn.json';
import vnflags from './flags/vietnam.png'; 
import {isMobile} from "react-device-detect";
import engflags from './flags/uk.png';

const SelectLang = () => {

    const [isOpen, setisOpen] = useState(false)
    const toggle = () => {setisOpen(!isOpen)} 
    const [isSelected, setIsSelected] = useState({
        vn : true,
        eng: false,
    })

    function onSelectLanguageVN (e) {
        context.selectLanguage(e)
        setIsSelected({
            vn : true,
            eng : false,
        })
    }

    function onSelectLanguageEN (e) {
        context.selectLanguage(e)
        setIsSelected({
            vn : false,
            eng : true,
        })
    }

    const context = useContext(Context)
    console.log(context.selectLanguage)
    if (isMobile) {
        return(
            <div style={{position: 'relative'}}>
            <div className='dropdown-lang-box-mobile'>
                <div className="nav" style= {{justifyContent: "center", fontSize: "0.7rem"}}>
                    <FormattedMessage id="lang" defaultMessage={VietNam['lang']}/>
                </div>
                <div className="icon-lang-container">
                    <img src={vnflags} id="vn"  onClick={(e) => {onSelectLanguageVN(e) }} className={`img-lang-dropdownbox ${isSelected.vn ? "lang-active-trigger" :  ""}`} alt="flags"/>
                    <img src={engflags} id="en" onClick={(e) => {onSelectLanguageEN(e)}} className={`img-lang-dropdownbox ${isSelected.eng ? "lang-active-trigger" :  ""}`} alt="flags"/>           
                </div>
            </div>
            </div>
        )
    }
    return (
        <>
        <div className="lang-container"> 
            <img src={isSelected.eng ? engflags :vnflags} style={{width: "25%"}} onClick = {()=> (toggle())} alt="flags"/>
            { isOpen && (
            <div className='dropdown-lang-box'>
                <div className="nav" style= {{justifyContent: "center"}}>
                    <FormattedMessage id="lang" defaultMessage={VietNam['lang']}/>
                </div>
                <div className="icon-lang-container">
                    <img src={vnflags} id="vn"  onClick={(e) => {onSelectLanguageVN(e) }} className={`img-lang-dropdownbox ${isSelected.vn ? "lang-active-trigger" :  ""}`} alt="flags"/>
                    <img src={engflags} id="en" onClick={(e) => {onSelectLanguageEN(e)}} className={`img-lang-dropdownbox ${isSelected.eng ? "lang-active-trigger" :  ""}`} alt="flags"/>           
                </div>
            </div>)} 
        </div>
        </>
    )
}

export  default SelectLang;