import QuoteItem from './QuoteItem';
import React,{useEffect, useState, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getData_quotes} from "../../actions"
import {isMobile} from "react-device-detect";
import {Context} from 'components/Language';
import Button from 'react-bootstrap/Button';
import {FormattedMessage} from 'react-intl';
import VietNam from '../../lang/vn.json';

const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;

const QuoteList = (props) => {
  const data = props.data;

  const context = useContext(Context)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getData_quotes(`${REACT_APP_API_ADMIN_URL}/quote-collection/get-quote-collection/true`))
  }, [])

  const data_quotes = useSelector(state => state.fetchQuotes);
  
  
  const [pagination ,setPagination] = useState(
    {
      _start: 0, 
      _end : 6,
    }, 
  )

  const [paginationMobile ,setPaginationMobile] = useState(
    {
      _start: 0, 
      _end : 3,
    }, 
  )

  function desktopHandle(start,end){

    if (end > data_quotes.data?.length) {
      let sub = end -  data_quotes.data?.length
      setPagination({
        _start : start,
        _end : end - sub, 
      })
    }
    else if (end===data_quotes.data?.length -6){
      let sub = (start + 6) - (data_quotes.data?.length -6)
      setPagination({
        _start : start,
        _end : end + sub, 
      })
    }
    else {
      setPagination({
        _start : start,
        _end : end, 
      })
    }
  }

  function desktopHandle_mobile(start,end){
    if (end > data_quotes.data?.length) {
      let sub = end - data_quotes.data?.length
      setPaginationMobile({
        _start : start,
        _end : end - sub, 
      })
    }
    else if (end === data_quotes.data?.length-3){
      let sub = (start + 3) - (data_quotes.data?.length -3)
      setPaginationMobile({
        _start : start,
        _end : end + sub, 
      })
    }
    else {
      setPaginationMobile({
        _start : start,
        _end : end, 
      })
    }

  }

  const dataFinal = []

  if  (context.locale === "en") {
    data_quotes.data?.forEach( item => dataFinal.push({
      quotecollection : item.quoteCollectionName?.en,
      thumbnail : item.thumbnailUrl,
      id : item.id, 
      quoteGallery: item.quoteGalleryCollections,
    }));
  } 
  else {
    data_quotes.data?.forEach( item => dataFinal.push({ 
      quotecollection : item.quoteCollectionName?.vi,
      thumbnail : item.thumbnailUrl,
      id : item.id, 
      quoteGallery: item.quoteGalleryCollections,
    }));
  }

  if  (isMobile) {
    return (
      <>
        <div className={`quote-list`}>
          {dataFinal?.slice(paginationMobile._start,paginationMobile._end).map((item) => {
            return <QuoteItem key={item.id} dataGallery={item.quoteGallery} image={item.thumbnail} title={item.quotecollection} contentIdx={item.id} />;
          })}
        </div>
        <div className='media-data-btn-container'>
          <Button  variant="outline-warning" style={{marginRight: "1rem"}} disabled={paginationMobile._start === 0 } onClick={ ()=> {desktopHandle_mobile(paginationMobile._start - 3,paginationMobile._end - 3)}} ><FormattedMessage id="media.btn-trc" defaultMessage={VietNam['media']['btn-trc']}/></Button>
          <Button  variant="outline-warning" style={{marginLeft: "1rem"}} disabled={paginationMobile._end === data_quotes.data?.length || data_quotes.data?.length <= 3} onClick={ ()=> {desktopHandle_mobile(paginationMobile._start + 3,paginationMobile._end + 3)}}><FormattedMessage id="media.btn-sau" defaultMessage={VietNam['media']['btn-sau']}/></Button>
        </div>
      </>
    );
  }
    return (
      <>
        <div className={`quote-list`}>
          {dataFinal?.slice(pagination._start,pagination._end).map((item) => {
            return <QuoteItem key={item.id} dataGallery={item.quoteGallery} image={item.thumbnail} title={item.quotecollection} contentIdx={item.id} />;
          })}
        </div>
        <div className='media-data-btn-container'>
          <Button  variant="outline-warning" style={{marginRight: "1rem"}} disabled={pagination._start === 0 } onClick={ ()=> {desktopHandle(pagination._start - 6,pagination._end - 6)}} ><FormattedMessage id="media.btn-trc" defaultMessage={VietNam['media']['btn-trc']}/></Button>
          <Button  variant="outline-warning" style={{marginLeft: "1rem"}} disabled={pagination._end === data_quotes.data?.length || data_quotes.data?.length <= 6} onClick={ ()=> {desktopHandle(pagination._start + 6,pagination._end + 6)}}><FormattedMessage id="media.btn-sau" defaultMessage={VietNam['media']['btn-sau']}/></Button>
        </div>
      </>
    );
};
export default QuoteList;
