import CauouselSliderContainer from 'components/CarouselSliderContainer'; 

import {FormattedMessage, FormattedDate} from 'react-intl';
import VietNam from '../../lang/vn.json';

import React,{useEffect, useContext} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Context } from 'components/Language';
import { getData_Vid, getYoutubeThumbnail} from 'actions';
import CustomSlider from 'components/Media/CustomerSlider';


const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;

const Charity = (props) => {

const context = useContext(Context)

const dispatch =useDispatch();
useEffect(() => {
  dispatch(getData_Vid(`${REACT_APP_API_ADMIN_URL}/media/get-all-media-collection/video`))
}, [])



const data = useSelector(state => state.fetchVid);

const filter_data = data.data?.filter((item) => item.mediaCategory === "charity")

const final_data = []
const charity_text = []

filter_data?.map((i) => (
    i.mediaList.map( (link) => ( final_data.push(getYoutubeThumbnail(link))))
  ))

const charityData = props.data.data?.filter((item) => item.textCategory === "charity")



if  (context.locale === "en") {
  charityData && charityData[0].content.en.forEach( item => charity_text.push(item))
}
else {
  charityData && charityData[0].content.vi.forEach( item => charity_text.push(item))
}

  return (
  <>
    
    <div className='insurance-content-mobile charity-content'>

      {charity_text.map( (item,i) => {
        if (i === charity_text.length -1){
          return (<p>{charity_text[charity_text.length - 2]} <a className="link-click" href="https://gvf.vn" >{item}</a></p>)
        }
        else if (i === charity_text.length -2) {
          return (<></>)
        }else {
          return (<p>{item}</p>)
        }
  
      })}
      <p>
        <FormattedMessage id="thiennguyen-page.para1" defaultMessage={VietNam['thiennguyen-page']['para1']}/>
      </p>
      <p>
        <FormattedMessage id="thiennguyen-page.para2" defaultMessage={VietNam['thiennguyen-page']['para2']}/>
      </p>
      <p>
        <FormattedMessage id="thiennguyen-page.para3" defaultMessage={VietNam['thiennguyen-page']['para3']}/>
      </p>
      <ul>
            <li><FormattedMessage id="thiennguyen-page.para3-li1" defaultMessage={VietNam['thiennguyen-page']['para3-li1']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li2" defaultMessage={VietNam['thiennguyen-page']['para3-li2']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li3" defaultMessage={VietNam['thiennguyen-page']['para3-li3']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li4" defaultMessage={VietNam['thiennguyen-page']['para3-li4']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li5" defaultMessage={VietNam['thiennguyen-page']['para3-li5']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li6" defaultMessage={VietNam['thiennguyen-page']['para3-li6']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li7" defaultMessage={VietNam['thiennguyen-page']['para3-li7']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li8" defaultMessage={VietNam['thiennguyen-page']['para3-li8']}/></li>
            <li><FormattedMessage id="thiennguyen-page.para3-li9" defaultMessage={VietNam['thiennguyen-page']['para3-li9']}/></li>
      </ul>
      <p>
      <FormattedMessage id="thiennguyen-page.para4" defaultMessage={VietNam['thiennguyen-page']['para4']}/> <a className="link-click" href="https://gvf.vn" >gvf.vn</a>
      </p>
    </div>
    <CauouselSliderContainer vidlist= {final_data} linkClass={"carousel-media"} title={<FormattedMessage id="thiennguyen-page.vid-section-title" defaultMessage={VietNam['thiennguyen-page']['vid-section-title']}/>} totalNum ={3}/>
    <CustomSlider/>
  </>
  );
};

export default Charity;
