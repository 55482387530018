import Navigation from 'components/Navigation/Navigation';
import Layout from 'layouts/Layout';
import Footer from "components/Footer/index";
import React, {createContext,useState} from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, IconButton, DialogContent } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import thongbao from "./assets/thongbao.jpg";

export const webContext = createContext(null);
const App = () => {
  const [ open, setOpen ] = useState(true)
  const [isActive, setIsActive] = useState();
  return (
    <div className='app'>
      <webContext.Provider value={{isActive,setIsActive}}>
        <Navigation />
        <Layout />
        <Footer />
      </webContext.Provider>

      {/* <Dialog
          open= {open}
        >
        <DialogTitle>
          <IconButton onClick ={ () => setOpen(false) } >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={thongbao} height="90%"/>
        </DialogContent>
      </Dialog> */}
    </div>
  );
};
export default App;
