const reducerFetchText =  (state=[], action) => {
    switch (action.type) {
        case "SET_DATA_TEXT": 
            return (state = action.data) 

        default: 
            return state; 
    }
}


export default reducerFetchText;