import axios from 'axios'; 
export const setData_Image = (data) => 
    ({type: 'SET_DATA_IMG', data})

export const getData_Image = (url) => {
    return (dispatch) => {
        axios.get(url)
        .then((res) => { dispatch(setData_Image(res.data))})
        .catch ((error) => {
            console.log(error)
            })
        }
}

export const setData_New = (data) => 
    ({type: 'SET_DATA_NEW', data})

export const getData_New = (url) => {
    return (dispatch) => {
        axios.get(url)
        .then((res) => { dispatch(setData_New(res.data))})
        .catch ((error) => {
            console.log(error)
            })
        }
}

export const setData_Text = (data ) => 
({type: "SET_DATA_TEXT", data})

export const getData_Text = (url) => {
  return (dispatch) => {
    axios.get(url)
      .then((res) => {dispatch(setData_Text(res.data))})
      .catch((error) => {console.log(error)}) 
  }
}

export const setData_quotes = (data) => (
  {type: "SET_DATA_QUOTES", data}
)

export const getData_quotes = (url) => {
  return(dispatch) => {
    axios.get(url)
      .then((res) => {dispatch(setData_quotes(res.data))})
      .catch((error ) => {
        console.log(error)
      })
  }
}

export const setData_Vid = (data) => 
    ({type: 'SET_DATA_VID', data})

export const getData_Vid = (url) => {
    return (dispatch) => {
        axios.get(url)
        .then((res) => { dispatch(setData_Vid(res.data))})
        .catch ((error) => {
            console.log(error)
            })
        }
}

export const setData_Testinomial = (data) => 
    ({type: 'SET_DATA_TESTI', data})

export const getData_Testinomial = (url) => {
    return (dispatch) => {
        axios.get(url)
        .then((res) => {dispatch(setData_Testinomial(res.data))})
        .catch ((error) => {
            console.log(error)
            })
        }
}


export const getYoutubeThumbnail = (url, quality) => {
    if (url) {
      var video_id, thumbnail, result;
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
        video_id = result.pop();
      } else if ((result = url.match(/youtu.be\/(.{11})/))) {
        video_id = result.pop();
      }
  
      if (video_id) {
        if (typeof quality == "undefined") {
          quality = "high";
        }
  
        var quality_key = "maxresdefault"; // Max quality
        if (quality === "low") {
          quality_key = "sddefault";
        } else if (quality === "medium") {
          quality_key = "mqdefault";
        } else if (quality === "high") {
          quality_key = "hqdefault";
        }
  
        var thumbnail =
          "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return {image: thumbnail, id: video_id};
      }
    }
    return false;
  };
  