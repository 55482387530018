import React, {useEffect,useState,createContext} from "react";

//Test fetch api 
import {getData_Image} from "../../actions/index"; 
import "lightgallery.js/dist/css/lightgallery.css";

import {useDispatch,useSelector}from 'react-redux';
import {useLightgallery} from 'react-lightgallery';
import GalleryImage from './GalleryImage';

//import GalleryContainer from "./GalleryContainer";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {Carousel} from "react-responsive-carousel";


export const Context = createContext()


const REACT_APP_API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL;


const CustomSlider = (props) => {

  const [collect , setCollect] = useState("");
  const { openGallery } =useLightgallery();
  const dispatch = useDispatch();
  //Test fetch by redux
  useEffect( () => {
    dispatch(getData_Image(`${REACT_APP_API_ADMIN_URL}/media/get-all-media-collection/image`));  
        }
  ,[])


  const data = useSelector(state => state.fetchImg);
  
      return (
        <>
        <Context.Provider value={{collect, setCollect}}>
        <Carousel showIndicators={false} infiniteLoop autoPlay width="80%" centerMode stopOnHover >
          {
          data.data?.map((items) => (
          <div key= {items.id} className="img_container">
            <img className="img_caur" src= {items.mediaList[0]} alt= {items.collectionName} />
            <div className="div-hover" onClick={() => ( openGallery(items.collectionName), setCollect(items.collectionName))}>
              <h2 className="title-collection">{items.collectionName}</h2>
              <h2 className="numimg-collection">+ {items.mediaList.length}</h2>
            </div>
          </div>
          ))
          }
        </Carousel>
        <div style={{ display:"none" }}>
           {data.data?.map( filtered => 
                (filtered.mediaList.map((l, i) => 
                    <GalleryImage key ={i} image={l} thumb={l} group={filtered.collectionName} />
                ))
            )}
        </div>
        </Context.Provider> 
        </>)

}
export default CustomSlider;